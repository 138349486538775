import Button from "../Button";
import Phone from "../Phone";
import "./style.scss";
import {useEffect, useRef, useState} from 'react';
import callAPI from "../../api";
import {isIOS, isMobile} from "react-device-detect";
import {useSystem} from "../../hooks/use-system";
import OTPField from "../OTPField";
import VideoPlayer from "../VideoPlayer";
import {Overlay, Tooltip} from "react-bootstrap";
import VideoPlayer2 from "../VideoPlayer/VideoPlayer2";
import {Play} from "react-feather";

function Flow({language, setBottomParagraph, setParentStep=() => {}}) {

    const {config} = useSystem();


    var _supportsLocalStorage = !!window.localStorage
        && typeof localStorage.getItem === 'function'
        && typeof localStorage.setItem === 'function'
        && typeof localStorage.removeItem === 'function';

    const testURL = 'https://signups.playhub.store/BulgariaDCB'
    const localURL =  'http://localhost:3000/'
    const isTesting = window.location.href === localURL || window.location.href === localURL
    const PlayerContainerRef = useRef();
    useEffect(() => {
        config?.approach === 'VIDEO' && setTimeout(() => PlayerContainerRef.current && PlayerContainerRef.current.classList.add("active"), 1500)
    }, [config])


    const system = useSystem();

    const [step, setStep] = useState(0);
    const [agreed, agree] = useState(config.terms_checkbox_switch ? config.default_terms_switch_status : true);
    const [phone, setPhone] = useState('');
    const [pin, setPin] = useState('');
    const [verificationRequest, setVerificationRequest] = useState(null);
    const [loading, isLoading] = useState(false);
    const [errorMessage, setError] = useState("");
    const [shortCode, setShortCode] = useState(null);
    const [keyword, setKeyword] = useState(null);
    const [validPhone, phoneIsvalid] = useState(false);
    const [carrierSubCode, setCarrierSubCode] = useState(null);
    const [lookupStat, setLookupStat] = useState(null);
    const [lookingUp, startLooking] = useState(false);

    const ref = useRef(null);
    const [shown, showPopover] = useState(false);
    const target = useRef(null);

    const nextStep = (shortCode, keyword) => {
        if (config.flow === 'PREMIUM_CLICK_2_SMS') {
            setStep(step + 1);
        } else if (config.flow === 'PREMIUM_CLICK_2_AUTO_SMS') {
            if (step == steps.length - 1) {
                system.showModal('autoSMS', {
                    texts: config.texts[system.language][step + 1], short_code: shortCode, keyword: config.keyword,
                    duration: 0.1
                })
            } else {
                setStep(step + 1);
            }
        } else if (config.flow === 'CLICK_2_SMS' || config.flow === 'PIN_FLOW') {
            setStep(step + 1);
        } else if (config.flow == 'LOOKUP_2_SMS') {
            setStep(step + 1);
        } else if (config.flow == 'INIT_MSISDN_REDIRECT') {
            setStep(step + 1);
        } else if (config.flow == 'CLICK_2_SMS_REDIRECT' || config.flow === 'MSISDN_REDIRECT') {
            setStep(step + 1);
        }
    }

    const listentoSubscription = (ref_id, session_id) => {
        callAPI({
            token: ref_id,
            session_id: session_id
        }, 'listenSubscription').then((data) => {
            if (data.status === 'success') {
                window.location.href = data.payload.redirect_link
            } else {
                setTimeout(() => {
                    //isLoading(true);
                    listentoSubscription(ref_id, session_id)
                }, 5000);
            }
        });
    }

    const prepareAndInitSMS = (short_code, keyword) => {

        const smsCommand = isIOS ? `sms://open?addresses=${short_code};?&body=${keyword}` : `sms:${short_code};?&body=${keyword}`;
        window.location.assign(smsCommand)

    }

    const callInitSubscription = (csc = null) => {
        callAPI({
            phone_number: phone,
            country_code: config.country_code,
            path: window.location.pathname,
            carrier_sub_code: csc ? csc : carrierSubCode,
            session_id: localStorage.getItem('nj_sessionId'),
            token: localStorage.getItem('nj_token'),
            supportStorage: _supportsLocalStorage,
            web_click_id: system.webClickId,
            config_id: system.config.public_id,
            qs: window.location.search,
            language: system.language,
            af_token: config.antifraud_token,
        }, 'initSubscription').then((data) => {
            if (data.status === 'success') {

                if (config.flow == 'CLICK_2_SMS_REDIRECT') {

                    //const sms_suffix = localStorage.getItem('dh_sms_suffix')
                    const curKeyword = data.payload.keyword; //+ (sms_suffix? ` ${sms_suffix}`: null);

                    prepareAndInitSMS(data.payload.short_code, curKeyword);
                    listentoSubscription(data.payload.ref_id, data.payload.session_id);

                }else{
                    if(data.payload.new_page_config){
                        system.setConfig(data.payload.new_page_config)
                    }

                    if (data.payload.ref_id) {
                        setVerificationRequest(data.payload.ref_id)
                        console.log('Setting ref id: %s', data.payload.ref_id)
                    }

                    // Don't move to next step since its done already upon submitting the number
                    if (config.flow != 'PIN_FLOW') {

                        if (config.flow == 'INIT_MSISDN_REDIRECT' || config.flow == 'MSISDN_REDIRECT') {
                            listentoSubscription(data.payload.ref_id, data.payload.session_id);
                        }
                        if (config.flow == 'INIT_MSISDN_REDIRECT' || config.flow == 'MSISDN_REDIRECT' || config.flow == 'LOOKUP_2_SMS') {
                            if (config.open_sms_composer){
                                prepareAndInitSMS(data.payload.short_code, data.payload.keyword);
                            }
                        }

                        if (data.payload.short_code) {
                            setShortCode(data.payload.short_code);
                            setKeyword(data.payload.keyword);
                            nextStep(data.payload.short_code, data.payload.keyword);
                        } else {
                            setShortCode(config.short_code);
                            nextStep(config.short_code)
                        }
                    }
                }
            } else {
                setError(data.message.split("_").join(" ").toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()))
            }
            isLoading(false);
        })
    }

    useEffect(() => {
        setBottomParagraph(config.texts[system.language][step].paragraph_under_button);
        setParentStep(step)
    }, [step])


    useEffect(() => {
        if (loading) {
            callInitSubscription(carrierSubCode);
        }
    }, [carrierSubCode])

    useEffect(() => {
        if (lookupStat === false && loading) {
            isLoading(false);
            callInitSubscription();
        }
    }, [lookupStat])

    useEffect(() => {
        if (agreed) {
            showPopover(false);
        }
    }, [agreed])


    function canLookup(innerIsValid) {
        if (innerIsValid && phone && !lookingUp && !errorMessage) {
            startLooking(true)
            callAPI({
                phone_number: phone,
                country_code: config.country_code,
                path: window.location.pathname,
                web_click_id: system.webClickId,
                config_id: system.config.public_id,
            }, 'lookup').then((data) => {
                if (data.status === 'success') {
                    setCarrierSubCode(data.payload.carrier_sub_code);
                } else {
                    setLookupStat(false);
                    // console.log("Loading: %s", loading)
                    // if(loading){
                    //     setError(data.message.split("_").join(" ").toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()))
                    // }
                    console.log(data.message.split("_").join(" ").toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()))
                }
                startLooking(false)
            })
        }
    }

    function CarrierButtons({smsSuffix}) {
        return (
            <div className="d-flex flex-column flex-md-row mt-4 mt-md-5">
                {config.carriers?.map(carrier =>
                    <Button button_text={carrier.name}
                            className={'carriers-btn'}
                            button_primary_color={config.button_primary_color}
                            button_text_color={config.button_text_color}
                            button_action={() => {
                                const smsCommand = isIOS ? `sms://open?addresses=${carrier.short_code};?&body=${carrier.keywords.OPT_IN[0]}${smsSuffix ? smsSuffix : ''}` : `sms:${carrier.short_code};?&body=${carrier.keywords.OPT_IN[0]}${smsSuffix ? smsSuffix : ''}`;
                                window.location.assign(smsCommand)
                            }}
                            key={carrier.short_code}
                    />
                )}
            </div>
        )
    }

    function OTPStep() {
        return (
            <div>
                <h1 className={'title'} style={{color: config.titles_color}}>
                    {config.texts[system.language][step].title}
                </h1>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="subTitle"
                                 dangerouslySetInnerHTML={{__html: config.texts[system.language][step].description}}></div>
                        </div>
                    </div>
                </div>


                <div className="container mb-4">
                    <div className="row">
                        <div className="col text-center">
                            <OTPField color={config.button_primary_color} ticketNumber={pin} setTicketNumber={setPin}
                                      disabled={loading}/>
                        </div>
                    </div>
                </div>

                <div className={'text-danger my-2'}>{errorMessage}</div>

                <Button disabled={!isTesting && (loading || !pin || (pin && pin.length < 4))}
                        button_text={config.texts[system.language][step].button_text}
                        button_primary_color={config.button_primary_color}
                        button_text_color={config.button_text_color}
                        button_action={() => {
                            if (isTesting) {
                                window.location.href = "https://bg.applandstore.com/en/";
                            } else {
                                isLoading(true);
                                callAPI({
                                    verification_request: verificationRequest,
                                    code: pin,
                                    path: window.location.pathname,
                                    web_click_id: system.webClickId,
                                    config_id: system.config.public_id,
                                    language: system.language
                                }, 'verifyOTP').then((data) => {
                                    if (data.status === 'success') {
                                        //setError('Subscribed!')
                                        window.location.href = data.payload.web_url;
                                    } else {
                                        setError(data.message.split("_").join(" ").toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()))
                                    }
                                    isLoading(false);
                                })
                            }

                        }}
                />

            </div>
        )
    }

    function PhoneStep() {
        return (
            <div>
                <h1 className={'title'} style={{color: config.titles_color}}>
                    {config.texts[system.language][step].title}
                </h1>
                <h5 style={{color: config.titles_color}}>
                    {config.texts[system.language][step].sec_title}
                </h5>
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <div className="subTitle"
                                 dangerouslySetInnerHTML={{__html: config.texts[system.language][step].description}}></div>
                        </div>
                    </div>
                </div>


                <Phone country_code={config.country_code} disabled={loading} phone={phone}
                       limitMaxLength={config.validate_msisdn}
                       onChange={(e, innerIsValid) => {
                           setPhone(e);
                       }} isValidPhone={phoneIsvalid} validPhone={config.validate_msisdn ? validPhone : true}/>
                {/*onChange={(e, innerIsValid) => {setLookupStat(null); setPhone(e); canLookup(innerIsValid);}} isValidPhone={phoneIsvalid}/>*/}

                <div className={'text-danger mb-2'}>{errorMessage}</div>

                {config.terms_checkbox_switch && (
                    <div className={'my-2 mx-1'} style={{color: config.titles_color}} ref={ref}>
                        <Overlay
                            show={shown}
                            target={target}
                            placement="bottom"
                            container={ref}
                            containerPadding={20}
                        >
                            <Tooltip placement={'bottom'}>Select this checkbox!</Tooltip>

                        </Overlay>
                        <input ref={target} onChange={() => agree(!agreed)} type={'checkbox'}
                               checked={agreed}/> {config.terms_agreement_text}

                        {config.append_terms_link_to_consent &&
                            <a
                                style={{color: config.titles_color}}
                                href={config.terms_url}
                                target={'_blank'}>{config.terms_text}</a>
                        }


                    </div>
                )}

                <Button disabled={!isTesting && (loading || !phone || (config.validate_msisdn ? !validPhone : false))}
                        button_text={config.texts[system.language][step].button_text}
                        button_primary_color={config.button_primary_color}
                        button_text_color={config.button_text_color}
                        button_action={() => {

                            if (isTesting) {
                                nextStep()
                            } else {

                                const shouldAgree = config.terms_checkbox_switch && config.terms_autocheck_with_click && !agreed

                                if (shouldAgree) {
                                    agree(true);
                                }

                                if (agreed || shouldAgree) {
                                    setError('')
                                    isLoading(true);
                                    console.log('set loading')

                                    if (!lookingUp) {
                                        callInitSubscription();
                                        // Move to next step in case of pin flow
                                        if (config.flow === 'PIN_FLOW') {
                                            if(shouldAgree){
                                                setTimeout(function (){
                                                    nextStep();
                                                }, 250);
                                            }else{
                                                nextStep();
                                            }

                                        }
                                    }
                                } else {
                                    showPopover(true);
                                    setError('Трябва да приемете Общите условия.')
                                }

                            }

                        }}
                />
            </div>
        )
    }

    function ResultStep() {

        const smsSuffix = localStorage.getItem('nj_smsSuffix');

        let smsText = keyword ? keyword : config.keyword;
        if (smsSuffix) {
            smsText += ` ${smsSuffix}`;
        }

        let smsShortCode = shortCode ? shortCode : config.short_code;


        // in case we want to show text instead of action buttons
        let displayOnlyText = false

        return (
            <div className="text-center">
                <h1 className={'title'} style={{color: config.titles_color}}>
                    {config.texts[system.language][step].title}
                </h1>

                {!displayOnlyText && <div className="subTitle"
                                          dangerouslySetInnerHTML={{__html: config.texts[system.language][step].description}}></div>}

                {displayOnlyText &&
                    <div className={'subTitle nomove'}>POŠALJI <b>{keyword}</b> NA BROJ TELEFONA <b>{shortCode}</b>
                    </div>}
                {config.show_carriers ?
                    <CarrierButtons smsText={smsSuffix}/>
                    : !displayOnlyText && <Button button_text={config.texts[system.language][step].button_text}
                                                  button_primary_color={config.button_primary_color}
                                                  button_text_color={config.button_text_color}
                                                  disabled={loading || (config.flow == 'CLICK_2_SMS' && !config.short_code)}
                                                  button_action={() => {
                                                      console.log(config.flow);
                                                      if (config.flow == 'CLICK_2_SMS_REDIRECT') {
                                                          isLoading(true);
                                                          callInitSubscription();
                                                      } else {
                                                          const smsCommand = isIOS ? `sms://open?addresses=${smsShortCode};?&body=${smsText}` : `sms:${smsShortCode};?&body=${smsText}`;
                                                          window.location.assign(smsCommand)
                                                      }
                                                  }}
                />
                }
                {errorMessage && <p className={'text-danger fw-bold'}>{errorMessage}</p>}

            </div>
        )
    }

    function PrepStep() {
        const handleClick = () => {
            if (config.template === 'generic-download-video') {
                PlayerContainerRef.current && PlayerContainerRef.current.classList.remove("active")
                setTimeout(() => PlayerContainerRef.current && PlayerContainerRef.current.classList.add("active"), 1200)
            }
            nextStep();
        }
        return (
            <div className="text-center">
                {
                    config.texts[system.language][step].title &&
                    <h1 className={'title mb-5'} style={{color: config.titles_color}}>
                        {config.texts[system.language][step].title}
                    </h1>
                }

                <div className="subTitle"
                     dangerouslySetInnerHTML={{__html: config.texts[system.language][step].description}}></div>
                {config.template === 'generic-download-video' &&
                    <div className="video-play-btn" onClick={handleClick}>
                        <Play strokeWidth={0} fill="#fff" size={isMobile ? 50 : 50} className="play-icon"/>
                    </div>
                }

                <Button button_text={config.texts[system.language][step].button_text}
                        button_primary_color={config.button_primary_color}
                        button_text_color={config.button_text_color}
                        button_action={handleClick}
                        className="prep-btn"
                />
            </div>
        )
    }


    const stepsByFlow = {
        PIN_FLOW: config.prep_step ? [
            <PrepStep/>,
            <PhoneStep/>,
            <OTPStep/>
        ] : [
            <PhoneStep/>,
            <OTPStep/>
        ],
        PREMIUM_CLICK_2_SMS: config.prep_step ? [
            <PrepStep/>,
            <PhoneStep/>,
            <ResultStep/>
        ] : [
            <PhoneStep/>,
            <ResultStep/>
        ],
        PREMIUM_CLICK_2_AUTO_SMS: config.prep_step ? [
            <PrepStep/>,
            <PhoneStep nextStep={system.show}/>,
        ] : [
            <PhoneStep nextStep={system.show}/>
        ],
        CLICK_2_SMS: config.prep_step ? [
            <PrepStep/>,
            <ResultStep/>
        ] : [
            <ResultStep/>
        ],
        CLICK_2_SMS_REDIRECT: config.prep_step ? [
            <PrepStep/>,
            <ResultStep/>
        ] : [
            <ResultStep/>
        ],
        INIT_MSISDN_REDIRECT: config.prep_step ? [
            <PrepStep/>,
            <PhoneStep/>,
            <ResultStep/>
        ] : [
            <PhoneStep/>,
            <ResultStep/>
        ],
        MSISDN_REDIRECT: config.prep_step ? [
            <PrepStep/>,
            <PhoneStep/>,
            <ResultStep/>
        ] : [
            <PhoneStep/>,
            <ResultStep/>
        ],
        LOOKUP_2_SMS: config.prep_step ? [
            <PrepStep/>,
            <PhoneStep/>,
            <ResultStep/>
        ] : [
            <PhoneStep/>,
            <ResultStep/>
        ]
    }

    const steps = stepsByFlow[config.flow];


    const FlowContainer = () => {
        return (
            <div className="container flow-container">
                <div className="row">
                    <div className="col text-center">
                        {steps[step]}
                    </div>
                    <div className="w-100 text-center">
                        <span><small><small>{config.texts[system.language][step].button_caption}</small></small></span>
                    </div>
                </div>
            </div>
        )
    }

    return (


        config.approach.includes('VIDEO')
            ?
            <div
                className={`video-player mx-auto ${config.approach === 'VIDEO' ? 'video-flow-container' : ''} ${config.template === 'generic-download-video' ? 'active' : ''}`}
                style={{width: '100%'}}
                ref={PlayerContainerRef}
                onClick={
                    () => config.approach === 'VIDEO_POPUP' ? system.showModal('flowModal', {
                        config,
                        setBottomParagraph
                    }) : null
                }
            >
                {config?.approach === 'VIDEO' &&
                    <>
                        <div className="video-loader">
                            <div className="spinner-border spinner-border-lg text-light"/>
                        </div>
                        <FlowContainer/>
                    </>
                }
                {config.template === 'generic-download-video' ?
                    <VideoPlayer2 bgClass={step > 0 ? 'blurred' : ''} bgImage={config.player_image}/>
                    : <VideoPlayer/>
                }

            </div>
            : <FlowContainer/>
    )
}

export default Flow;
